<template>
  <a-card :bordered="false" class="relative">
    <page-back-btn></page-back-btn>
    <div :class="$style.btn">
      <a-button @click="exportExcel">
        <a-icon type="export"></a-icon>
        导出
      </a-button>
    </div>
    <a-tabs type="card" @change="changeTab" :defaultActiveKey="activeKey">
      <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.title">
        <component :is="item.component"></component>
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>

<script>
import PageBackBtn from '@/components/PageBackBtn'
import fileDownload from 'js-file-download'

export default {
  components: { PageBackBtn },
  data() {
    return {
      tabs: [
        {
          title: '工人档案/工作履历',
          key: '1',
          component: () => import('./modules/Archives.vue'),
        },
        {
          title: '考勤信息',
          key: '2',
          component: () => import('./modules/Attendance.vue'),
        },
        {
          title: '工资表支付信息',
          key: '3',
          component: () => import('./modules/Wage.vue'),
        },
        {
          title: '工人借支信息',
          key: '4',
          component: () => import('./modules/BNR.vue'),
        },
      ],
      activeKey: '1',
    }
  },
  methods: {
    changeTab(key) {
      this.activeKey = key
    },
    async exportExcel() {
      const { cardId, leaderId, projectCode } = this.$route.query
      const params = { cardId, leaderId, projectCode }
      const res = await this.$axios.post('/report/evidence/chain/info/export', params, {
        interceptors: {
          response: false,
        },
        responseType: 'arraybuffer',
      })
      fileDownload(res.data, '工友证据链-详情.xls')
    },
  },
}
</script>

<style lang="less" module>
.btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
</style>
